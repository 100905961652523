@font-face {
  font-family: 'Cocogoose Pro';
  src: local('Cocogoose Pro Regular'),
    url('./CocogoosePro-Regular.woff2') format('woff2'),
    url('./CocogoosePro-Regular.woff') format('woff'),
    url('./CocogoosePro-Regular.otf') format('otf'),
    url('./CocogoosePro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Figtree';
  src: local('Figtree Regular'),
    url('./Figtree-Regular.woff2') format('woff2'),
    url('./Figtree-Regular.woff') format('woff'),
    url('./Figtree-Regular.otf') format('otf'),
    url('./Figtree-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Figtree';
  src: local('Figtree Medium'),
    url('./Figtree-Medium.woff2') format('woff2'),
    url('./Figtree-Medium.woff') format('woff'),
    url('./Figtree-Medium.otf') format('otf'),
    url('./Figtree-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Figtree';
  src: local('Figtree Bold'),
    url('./Figtree-Bold.woff2') format('woff2'),
    url('./Figtree-Bold.woff') format('woff'),
    url('./Figtree-Bold.otf') format('otf'),
    url('./Figtree-Bold.ttf') format('truetype');
  font-weight: 700;
}
